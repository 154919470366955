@import 'styles/spacing.scss';

.TableSorter {
  &__label {
    margin-right: $spacing-xs;
  }

  &__selector {
    width: 240px;
  }
}
