@import 'styles/spacing.scss';
@import 'styles/color.scss';

.TableFilterCheckbox {
  &__wrapper {
    padding: $spacing-xxs 0 $spacing-xxs $spacing-m;
  }

  &__text {
    color: $color-text;
    margin-bottom: 0;

    &-disabled {
      color: $color-gray-light;
      margin-bottom: 0;
    }
  }
}
