@import 'styles/spacing.scss';
@import 'styles/color.scss';

.AddUserModalContent {
  &__box {
    padding: $spacing-m 0 0 $spacing-m;
    border: 1px solid $color-border-grey-light;
    border-radius: $spacing-xs;
    margin: $spacing-s 0 $spacing-s 0;
    display: flex;
    flex-direction: row;
  }

  &__icon {
    margin-right: $spacing-xs;
  }

  &__list {
    padding: $spacing-s $spacing-s 0 $spacing-s;
    line-height: $spacing-xl;
  }
}