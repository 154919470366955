@import 'styles/spacing.scss';

.TabAssets {
  &__contextual-menu {
    margin: $spacing-xs;
  }

  &__left-action-section {
    margin: $spacing-xs;
  }

  &__left-action-section-spinner {
    margin-left: $spacing-xs;
  }
}