.Access {
  &__contextual-menu {
    display: flex;
    justify-content: space-between;
  }

  &__buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
