@import 'styles/spacing.scss';
@import 'styles/font.scss';

.QrManualRevokeModal {
  padding: $spacing-xl;
}

.QrManualRevokeModal__description {
  white-space: pre-wrap;
}

.QrManualRevokeModal .ant-modal-content {
  width: 100%;
}

.QrManualRevokeModal .ant-form-item-explain-error {
  font-size: $font-size-s;
}
