@import 'styles/spacing.scss';
@import 'styles/color.scss';
@import 'styles/font.scss';

.DeviceWithoutGatewayForm {
  &__text-wrapper {
    margin: $spacing-l 0;
  }

  &__text-wrapper-legend {
    color: $color-gray-extra-light;
    font-style: italic;
  }

  &__title {
    font-weight: bold;
    margin-bottom: $spacing-xxs;
  }

  &__wrapper {
    border: 1px solid $color-text-disabled;
    border-radius: $spacing-xs;
    padding: $spacing-xl;
    width: 100%;
  }

  &__wrapper-row {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-m;

    .ant-form-item {
      width: calc(50% - $spacing-xs);
      flex: inherit !important;
    }
  }

  &__sensors-list-wrapper {
    border: 1px solid $color-text-disabled;
    border-radius: $spacing-xs;
    padding: $spacing-xl $spacing-xl $spacing-xxxl $spacing-xl;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-m;
    align-items: flex-end;
  }

  &__sensor-wrapper {
    display: inline-block;
    width: calc(50% - $spacing-xs);
    flex: inherit !important;

    .ant-form-item {
      margin-bottom: $spacing-xs;
    }
  }
}
