@import 'styles/spacing.scss';
@import 'styles/font.scss';

.ValidateExternalInvitationModal {
  padding: $spacing-xl;

  &__loading {
    display: flex;
    justify-content: center;
  }
}

.ValidateExternalInvitationModal .ant-modal-content {
  width: 100%;
}

.ValidateExternalInvitationModal .ant-form-item-explain-error {
  font-size: $font-size-s;
}
