@import 'styles/spacing.scss';
@import 'styles/font.scss';

.RevokeExternalInvitationModal {
  padding: $spacing-xl;
}

.RevokeExternalInvitationModal .ant-modal-content {
  width: 100%;
}

.RevokeExternalInvitationModal .ant-form-item-explain-error {
  font-size: $font-size-s;
}
