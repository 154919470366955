.AssignUserToMultipleAssetsModal {
  &__wrapper {
    height: 200px;

    &--scroll {
      overflow-x: hidden;
      overflow-y: scroll;
    }
  }

  &__spinner {
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
