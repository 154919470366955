@import 'styles/spacing.scss';
@import 'styles/color.scss';
@import 'styles/font.scss';

.TableFilterDatePicker {
  &__wrapper {
    padding: 0 0 $spacing-xs $spacing-m;
  }

  &__wrapper-content {
    width: 100%;
  }

  &__wrapper-content-selector {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: $spacing-xxs;
  }

  &__wrapper-content-selector-full {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: $spacing-xxs;
    width: 100%;
  }

  &__selector {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__input-wrapper {
    display: flex;
    flex-grow: 4;
  }

  &__input-wrapper .ant-picker {
    flex: 1;
  }

  &__input-wrapper-full .ant-picker {
    width: 218px;
    margin-left: $spacing-xl;
  }

  &__text {
    color: $color-text;
    margin-bottom: 0;

    &-disabled {
      color: $color-gray-light;
      margin-bottom: 0;
    }
  }

  &__errorMessage {
    color: $color-alert;
    font-size: $font-size-s;
    margin-bottom: 0;
  }
}
