@import 'styles/spacing.scss';

.ExternalInvitationsListTemplate {
  &__wrapper {
    margin: $spacing-l;
    padding: $spacing-xl;
  }
}

.ExternalInvitationsListTemplate__wrapper div.ant-table-body {
  max-height: 100% !important;
}
