@import 'styles/color.scss';
@import 'styles/spacing.scss';

.PeripheralDevices__expanded-table-wrapper tr.ant-table-expanded-row > td {
  border: 1px solid $color-complementary !important;
}

.PeripheralDevices {
  &__pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: $spacing-l 0 $spacing-l $spacing-l !important;
  }
}
