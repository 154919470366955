@import 'styles/spacing.scss';

.QrAccessTableTemplate {
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $spacing-l;
  }

  &__header h2 {
    margin-bottom: 0;
  }

  &__pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: $spacing-l 0 $spacing-l $spacing-l !important;
  }
}
