@import 'styles/spacing.scss';
@import 'styles/color.scss';

.AddUserModalContentResponse {
  &__box-wrapper {
    padding: $spacing-m;
    border: 1px solid $color-border-grey-light;
    border-radius: $spacing-xs;
    margin: $spacing-s 0 $spacing-s 0;
    display: flex;
    flex-direction: row;
    background-color: $color-error-background;
  }

  &__box-icon-wrapper {
    margin-right: $spacing-xs;
  }

  &__box-icon {
    color: $color-alert;
  }

  &__box-list {
    padding: $spacing-s;
    line-height: $spacing-xl;
  }
}