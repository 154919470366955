@import 'styles/font.scss';
@import 'styles/spacing.scss';
@import 'styles/color.scss';

.CreateDeviceWithoutGatewayDrawer {
  &__wrapper .ant-drawer-header {
    padding: $spacing-xl $spacing-xxxl;
  }

  &__wrapper .ant-drawer-header-title {
    display: flex;
    flex-direction: row-reverse;
  }

  &__title {
    font-size: $font-size-xl;
    font-weight: 600;
  }

  &__wrapper .ant-drawer-header-title button {
    margin-right: 0;
  }

  &__wrapper .ant-drawer-body {
    padding: $spacing-xl $spacing-xxxl $spacing-xxxxxl $spacing-xxxl;
  }

  &__wrapper footer {
    position: absolute;
    height: 70px;
    width: 100%;
    background-color: $color-text-white;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: $spacing-l;
    gap: $spacing-m;
    border-top: 1px solid $color-gray-extra-extra-light;
  }

  &__text-wrapper {
    margin: $spacing-l 0;
  }

  &__text {
    margin-bottom: 0;
    font-weight: bold;
  }

  &__type-wrapper {
    border: 1px solid $color-text-disabled;
    border-radius: $spacing-xs;
    padding: $spacing-xl;
  }

  &__info {
    border-radius: 8px;
    border: 1px solid $color-gray-extra-light;
    padding: $spacing-m;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }
  &__info-icon {
    width: 24px;
  }

  &__info-text {
    font-size: $font-size-base;
    line-height: 20px;
    font-weight: 400;
    color: $color-text;
    margin-bottom: $spacing-xxxs;
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__type-selector {
    display: flex;
    flex-direction: column;
    gap: $spacing-xs;
    flex: 1;
  }

  &__form {
    margin-top: $spacing-xxs;
    margin-bottom: $spacing-xxs;
  }
}

.ant-select {
  margin-right: 0 !important;
  flex: 1 !important;
}
