@import 'styles/spacing.scss';

.DropdownContent {
  &__wrapper {
    width: 300px;
    -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }

  &__wrapper .ant-card-body {
    padding: 0;
  }

  &__wrapper-content {
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 8px;
  }

  &__wrapper-content::-webkit-scrollbar {
    width: 8px;
  }

  &__wrapper-content::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
  }

  &__wrapper-content::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &__wrapper-body {
    display: flex;
    flex-direction: column;
    padding: $spacing-xxs $spacing-s $spacing-xxs $spacing-s;
    margin-left: $spacing-xs;
  }

  &__subtitle {
    font-weight: bold;
    margin-top: $spacing-xxs;
    margin-bottom: $spacing-xxs;
  }

  &__divider {
    margin-top: 0 !important;
    margin-bottom: $spacing-xs !important;
  }

  &__footer {
    padding: $spacing-xxxs $spacing-xs $spacing-xs $spacing-xs;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: $spacing-xs;
  }
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0 !important;
}
